var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('UserAddEdit',{attrs:{"es-id":_vm.ae.esId,"stepper-mode":_vm.ae.stepperMode,"stepper-step":_vm.ae.stepperStep,"user-id":_vm.ae.userId,"user-data":_vm.ae.userData}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing px-4"},[_c('title-plus',{staticClass:"mb-10",attrs:{"title":_vm.$t('components.rentStationOwnersManagement.headline')},on:{"plus":_vm.onOpenES}})],1),[_c('FSTable',{attrs:{"fst-id":_vm.fstId,"qso":_vm.qso,"endpoint":_vm.indexDataEndpoint,"headers":_vm.tableHeaders},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [_c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',{staticClass:"flex items-center gap-1"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                    name: _vm.getDetailsPageRouteName(
                      item.role ? item.role.role_name : null
                    ),
                    params: { id: item.id },
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('span',[(item.is_active)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:("Verified"),expression:"`Verified`",modifiers:{"top":true}}],staticClass:"fas fa-check-circle text-green-600"}):_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:("Unverified"),expression:"`Unverified`",modifiers:{"top":true}}],staticClass:"h-4 w-4 text-red-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])])],1),_c('FSTableRowItem',[_vm._v(_vm._s(item.organization_name))]),_c('FSTableRowItem',[_vm._v(_vm._s(item.phone_number))]),_c('FSTableRowItem',[_vm._v(_vm._s(item.email))]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.role ? item.role.role_name : '--')+" ")]),_c('FSTableRowItem',[(item.last_login)?_c('div',[_vm._v(" "+_vm._s(_vm._f("friendlyDateTime")(item.last_login))+" ")]):_c('div',[_vm._v("-")])]),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":_vm.getUserStatusVariant(item.user_status),"text":_vm.getUserStatus(item.user_status),"profile":"user"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.onOpenES(item.id)}}}),_c('UserIndexActions',{attrs:{"primary-key":item.id,"data":item},on:{"accountStatusUpdated":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)]})]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }