<template>
  <base-layout>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />
    <content-section :spacing="false">
      <div class="content-spacing px-4">
        <title-plus
          :title="$t('components.agentUsersManagement.headline')"
          @plus="onOpenES"
          class="mb-10"
        />
        <div class="flex mt-4 mb-10 flex-col-8 md:items-center md:flex-row">
          <SummaryCard
            :title="$t('components.agentUsersManagement.summary.totalAgent')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />
        </div>
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :qso="qso"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem>
                  <router-link
                    class="font-bold text-blue-600 capitalize"
                    :to="{
                      name: 'ViewOrganizationUserProfile',
                      params: { id: item.id },
                    }"
                    target="_blank"
                  >
                    {{ item.full_name }}
                  </router-link>
                </FSTableRowItem>
                <FSTableRowItem>
                  {{ item.phone_number }}
                </FSTableRowItem>
                <FSTableRowItem>
                  {{
                    item.assigned_fleets.length
                      ? item.assigned_fleets.join(', ')
                      : '--'
                  }}
                </FSTableRowItem>
                <FSTableRowItem>
                  {{ symbol }}
                  {{ parseFloat(item.balance ? item.balance : 0).toFixed(2) }}
                </FSTableRowItem>
                <FSTableRowItem>
                  <div v-if="item.last_login">
                    {{ item.last_login | friendlyDateTime }}
                  </div>
                  <div v-else>--</div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <x-status
                    :variant="getUserStatusVariant(item.user_status)"
                    :text="getUserStatus(item.user_status)"
                    profile="user"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <div class="flex items-center">
                    <oto-edit-icon @click="onOpenES(item.id)" />
                    <UserIndexActions
                      :primary-key="item.id"
                      :data="item"
                      @accountStatusUpdated="
                        $store.dispatch('fsTable/fetchData')
                      "
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow> </template
          ></template> </FSTable
      ></template>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import UserAddEdit from './UserAddEdit'

import { AgentUserConfig } from '@/config/AgentUserConfig'
// import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
import UserIndexActions from './UserIndexActions.vue'
import { mapGetters } from 'vuex'
import SummaryCard from '@/components/cards/SummaryCard'
import XStatus from '@/components/badge/XStatus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
export default {
  name: 'AgentUsers',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    SummaryCard,
    OtoEditIcon,
    UserAddEdit,
    UserIndexActions,
    XStatus,
  },
  data() {
    return {
      fstId: 'AgentUserIndex',
      qso: { append: '', prepend: '?' },
      statusChoice: {
        A: 'Active',
        D: 'Inactive',
        H: 'On Hold',
      },
      symbol: '',
      indexDataEndpoint: AgentUserConfig.api.index,
      indexMetaData: {
        summary: {
          total: 0,
        },
      },
      tableHeaders: [
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.fullName'
          )}`,
          width: '13%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.phoneNumber'
          )}`,
          width: '`15`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.assignedFleets'
          )}`,
          width: '`15`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.balance'
          )}`,
          width: '`15`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.lastLogin'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.status'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.agentUsersManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    exportUrl() {
      return this.$store.getters['navigation/getCurrentListAPIExportLink']
    },
  },
  async mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    // TODO - remove the event listener
    // TODO - create a refresh index data mixin ?
    window.addEventListener(AgentUserConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    getUserStatus: function(status) {
      return status in this.statusChoice ? this.statusChoice[status] : '-'
    },
    getUserStatusVariant(status) {
      let collection = {
        A: 'green',
        D: 'red',
        H: 'orange',
      }
      return status in collection ? collection[status] : 'purple'
    },
    // EdgeStack component
    async onOpenES(userId = null) {
      // if userId provided, open it in free (aka edit) mode, inject user data as well
      // otherwise, open it in strict (aka add mode). In both case, inject required data,
      // but user data is required in edit (free) mode.
      if (userId) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        // fetch user data
        await this.$http
          .get(this.$config.orgUser.api.single(userId))
          .then((res) => {
            console.log('res-data', res.data)
            this.ae.stepperMode = 'free'
            this.ae.userId = res.data.id
            this.ae.userData = res.data
            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        // this.$xStepper.navigate(this.ae.esId).to(4)
        this.ae.stepperMode = 'strict'
        this.ae.userId = null
        this.ae.userData = null
        this.$edgeStack.open(this.ae.esId)
      }
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return id //FranchiseUserConfig.api.update(id);
    },
  },
}
</script>

<style></style>
