<template>
  <base-layout>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />
    <content-section :spacing="false">
      <div class="content-spacing px-4">
        <title-plus
          :title="$t('components.rentStationOwnersManagement.headline')"
          @plus="onOpenES"
          class="mb-10"
        />
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :qso="qso"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem class="flex items-center gap-1">
                  <router-link
                    class="font-bold text-blue-600 capitalize"
                    :to="{
                      name: getDetailsPageRouteName(
                        item.role ? item.role.role_name : null
                      ),
                      params: { id: item.id },
                    }"
                    target="_blank"
                  >
                    {{ item.full_name }}
                  </router-link>
                  <span>
                    <i
                      v-if="item.is_active"
                      class="fas fa-check-circle text-green-600"
                      v-tooltip.top="`Verified`"
                    />
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-red-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      v-tooltip.top="`Unverified`"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                </FSTableRowItem>
                <FSTableRowItem>{{ item.organization_name }}</FSTableRowItem>
                <FSTableRowItem>{{ item.phone_number }}</FSTableRowItem>
                <FSTableRowItem>{{ item.email }}</FSTableRowItem>
                <FSTableRowItem>
                  {{ item.role ? item.role.role_name : '--' }}
                </FSTableRowItem>
                <FSTableRowItem>
                  <div v-if="item.last_login">
                    {{ item.last_login | friendlyDateTime }}
                  </div>
                  <div v-else>-</div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <x-status
                    :variant="getUserStatusVariant(item.user_status)"
                    :text="getUserStatus(item.user_status)"
                    profile="user"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <div class="flex items-center">
                    <oto-edit-icon @click="onOpenES(item.id)" />
                    <UserIndexActions
                      :primary-key="item.id"
                      :data="item"
                      @accountStatusUpdated="
                        $store.dispatch('fsTable/fetchData')
                      "
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow> </template
          ></template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'

import OtoEditIcon from '@/components/ui/OtoEditIcon'
import UserAddEdit from './UserAddEdit'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'

import XStatus from '@/components/badge/XStatus'
// import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
import UserIndexActions from './UserIndexActions.vue'
import { useEndpoints } from '@/composables'
export default {
  name: 'UserRentStationOwnerIndex',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    UserAddEdit,
    UserIndexActions,
    XStatus,
  },
  data() {
    return {
      fstId: 'UserRentStationOwnerIndex',
      qso: { append: '', prepend: '?' },
      statusChoice: {
        A: 'Active',
        D: 'Inactive',
        H: 'On Hold',
      },
      indexDataEndpoint: useEndpoints.user.rentStationOwner.index(),
      tableHeaders: [
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.fullName'
          )}`,
          width: '23%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.orgName'
          )}`,
          width: '`13`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.phoneNumber'
          )}`,
          width: '`15`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.email'
          )}`,
          width: '`15`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.role'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.lastLogin'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.status'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentStationOwnersManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },

  methods: {
    getDetailsPageRouteName(role) {
      // TODO: add franchise route
      // if (role === "Owner") return "ViewOrganizationUserProfile";
      // if (role === "Operator") return "ViewOperatorUserProfile";
      // return "ViewOrganizationUser";
      console.log(role)
      return 'ViewOrganizationUserProfile'
    },
    getUserStatus: function(status) {
      return status in this.statusChoice ? this.statusChoice[status] : '-'
    },
    getUserStatusVariant(status) {
      let collection = {
        A: 'green',
        D: 'red',
        H: 'orange',
      }
      return status in collection ? collection[status] : 'purple'
    },
    // EdgeStack component
    async onOpenES(userId = null) {
      // if userId provided, open it in free (aka edit) mode, inject user data as well
      // otherwise, open it in strict (aka add mode). In both case, inject required data,
      // but user data is required in edit (free) mode.
      if (userId) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        // fetch user data
        await this.$http
          .get(this.$config.orgUser.api.single(userId))
          .then((res) => {
            console.log('res-data', res.data)
            this.ae.stepperMode = 'free'
            this.ae.userId = res.data.id
            this.ae.userData = res.data
            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        // this.$xStepper.navigate(this.ae.esId).to(4)
        this.ae.stepperMode = 'strict'
        this.ae.userId = null
        this.ae.userData = null
        this.$edgeStack.open(this.ae.esId)
      }
    },
  },
}
</script>

<style></style>
